<template>
  <div class="grey800 px-4 py-5 d-flex">
    <v-select
      v-model="form.field"
      :items="fields"
      filled dense flat
      class="rounded-0 mr-2"
      background-color="grey400"
      hide-details
      placeholder="Field"
      style="max-width: 200px;"
    />

    <v-text-field
      v-if="valueType === 'string'"
      v-model="form.value"
      filled dense flat class="rounded-0 mr-2"
      placeholder="Value"
      hide-details
      background-color="grey600"
    />
    <v-switch
      v-if="valueType === 'boolean'"
      v-model="form.value"
      inset dense class="mt-0"
      label="Value"
      hide-details
    />

    <div class="d-flex justify-end">
      <v-btn
        color="primary700" tile height="42"
        :ripple="false" :loading="loading"
        @click="onSet"
      >
        Set
      </v-btn>
    </div>
  </div>
</template>

<script>
const ENCRYPTABLES = [
  'password', 'sharedSecret', 'identitySecret',
  'csdealsKey', 'csdealsTotp',
]

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    bot: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    form: {
      field: null,
      value: null,
    },
  }),
  computed: {
    fields() {
      return ['disabled', 'tradeUrl', 'type', 'username', 'proxy', ...ENCRYPTABLES]
    },
    valueType() {
      if (this.form.field === 'disabled') return 'boolean'

      return 'string'
    },
  },
  watch: {
    'form.field': function fn(val) {
      if (ENCRYPTABLES.includes(val)) {
        this.form.value = null
      } else {
        this.form.value = this.bot?.[val] ?? null
      }
    },
    bot: function fn() {
      const { field } = this.form

      if (ENCRYPTABLES.includes(field)) {
        this.form.value = null
      } else {
        this.form.value = this.bot?.[field] ?? null
      }
    },
  },
  methods: {
    async onSet() {
      const { form } = this

      this.$emit('set', {
        field: form.field,
        value: form.value,
        username: this.bot.username,
      })
    },
  },
}
</script>
