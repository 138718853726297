<template>
  <div>
    <!-- user searching  -->
    <section class="mb-6">
      <div class="heading d-flex align-center mb-3">
        <h3 class="title">
          Search &amp; manage bots
        </h3>
        <v-spacer />
        <v-text-field
          v-model="search"
          solo dense hide-details flat
          class="rounded-0 text-caption mr-2"
          placeholder="Search by name or steamid"
          background-color="grey800"
        />
        <v-btn
          color="grey300"
          tile small class="unwidth unheight align-self-stretch"
          :ripple="false" :loading="loading.list"
          @click="fetchApiData"
        >
          <fai :icon="['fas','sync']" class="grey100--text" />
        </v-btn>
      </div>

      <v-data-table
        class="rounded-0 data-table"
        item-key="username"
        :headers="headers"
        :items="entries"
        :options.sync="options"
        :footer-props="{ itemsPerPageOptions: [5, 10, 25] }"
        :server-items-length="total"
        :expanded="expanded"
        :loading="loading.list"
        @pagination="onPagination"
      >
        <template #item.username="{ item, value }">
          <h4 class="text-body-2 grey100--text lh-1-2">
            <fai class="blue300--text mr-1" :title="item.type" :icon="botIcon(item.type)" />
            {{ value }}
            <fai v-if="item.disabled" title="disabled" class="primary600--text ml-1" :icon="['far', 'times']" />
            <fai v-if="item.bannedAt" title="banned" class="primary400--text text-body-1 ml-1" :icon="['fad', 'skull-crossbones']" />
          </h4>
          <h5 class="text-caption lh-1-2 text--disabled" v-text="item.steamid" />
        </template>

        <template #item.stats.itemCount="{ value, item }">
          <span v-if="!value" class="text--disabled">&mdash;</span>
          <div v-else class="text-body-2">
            <h4 class="text-body-2 green200--text lh-1-4">
              {{ value | toLocaleInt }} items
            </h4>
            <h5 class="text-caption green500--text lh-1-2">
              (${{ item.stats.itemValue / 100 | toCurrency }})
            </h5>
          </div>
        </template>

        <template #item.stats.tradeCount="{ value, item }">
          <span v-if="!value" class="text--disabled">&mdash;</span>
          <div v-else class="text-body-2">
            <h4 class="text-body-2 green200--text lh-1-4">
              {{ value | toLocaleInt }} trades
            </h4>
            <h5 class="text-caption grey200--text lh-1-2">
              <span>last: </span>
              <v-tooltip v-if="item.stats.lastTradeAt" top color="primary500">
                <template #activator="{on}">
                  <span v-on="on">{{ new Date(item.stats.lastTradeAt).getTime() | relativeTime('twitter') }}</span>
                </template>
                <span>{{ new Date(item.stats.lastTradeAt).getTime() | toDateFormat('YYYY-MM-DD HH:mm:ss') }}</span>
              </v-tooltip>
              <span v-else class="text--disabled">&ndash;</span>
            </h5>
          </div>
        </template>

        <template #item.stats.csdealsBalance="{ value }">
          <span v-if="!value" class="text--disabled">&mdash;</span>
          <h4 v-else class="text-body-2 green400--text">
            ${{ value / 100 | toCurrency }}
          </h4>
        </template>

        <template #item.createdAt="{ value, item }">
          <v-tooltip top color="primary500">
            <template #activator="{on}">
              <span v-on="on">{{ new Date(value).getTime() | relativeTime('twitter') }}</span>
            </template>
            <span>{{ new Date(value).getTime() | toDateFormat('YYYY-MM-DD HH:mm:ss') }}</span>
          </v-tooltip>
        </template>

        <template #item.actions="{ item }">
          <fai
            :icon="['fad', 'pencil']"
            class="primary500--text link"
            @click="onExpandItem(item)"
          />
        </template>

        <!-- transaction info -->
        <template #expanded-item="{ headers: head, item }">
          <td :colspan="head.length" class="grey800 py-3 data-detail">
            <AdminBotPropEdit
              :bot="item"
              :loading="loading.create"
              @set="onPropEdit"
            />

            <div class="grey900 px-4 py-5">
              <v-row>
                <v-col cols="12" md="6">
                  <h4 class="text-uppercase text-caption mb-2 primary500--text">
                    Quick actions
                  </h4>
                  <v-btn
                    block tile small
                    color="grey500" class="mb-1"
                    :loading="loading.quick"
                    @click="onGetBtcAddr(item)"
                  >
                    cs.deals btc depo address
                  </v-btn>
                  <v-btn
                    block tile small
                    color="grey500" class="mb-1"
                    :loading="loading.quick"
                    @click="onGetTradeUrl(item)"
                  >
                    bot trade url
                  </v-btn>
                </v-col>
                <v-col cols="12" md="6">
                  <h4 class="text-uppercase text-caption mb-2 primary500--text">
                    Actions response
                  </h4>
                  <CopyInput :value="quickOpts" class="grey700" />
                </v-col>
              </v-row>
            </div>
          </td>
        </template>
      </v-data-table>
    </section>

    <!-- create bot -->
    <section class="mb-6">
      <v-expansion-panels accordion flat tile>
        <v-expansion-panel>
          <v-expansion-panel-header color="grey600">
            Add new bot
          </v-expansion-panel-header>
          <v-expansion-panel-content color="grey800">
            <v-row class="pt-4">
              <v-col cols="12">
                <FileDropArea
                  label="Autofill from .json or .maFile"
                  :files="files" multiple
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="newBotForm.username"
                  solo dense flat
                  placeholder="Steam username"
                  hide-details
                  background-color="grey600"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="newBotForm.steamid"
                  solo dense flat
                  placeholder="STEAMID64"
                  hide-details
                  background-color="grey600"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="newBotForm.password"
                  solo dense flat
                  placeholder="Steam password"
                  hide-details
                  background-color="grey600"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="newBotForm.tradeUrl"
                  solo dense flat
                  placeholder="Trade URL"
                  hide-details
                  background-color="grey600"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="newBotForm.sharedSecret"
                  solo dense flat
                  placeholder="Steam shared secret"
                  hide-details
                  background-color="grey600"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="newBotForm.identitySecret"
                  solo dense flat
                  placeholder="Steam identity secret"
                  hide-details
                  background-color="grey600"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="newBotForm.proxy"
                  solo dense flat
                  placeholder="Proxy url"
                  hide-details
                  background-color="grey600"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="newBotForm.type"
                  solo dense flat
                  placeholder="Type (store, game, etc)"
                  hide-details
                  background-color="grey600"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-switch
                  v-model="newBotForm.disabled"
                  class="mt-0 ml-1" color="green500"
                  label="Disabled"
                  dense hide-details inset
                />
              </v-col>
              <v-col cols="6" class="d-flex justify-end">
                <v-btn
                  class="rounded-0 font-weight-bold"
                  color="green700 green300--text"
                  :ripple="false"
                  :loading="loading.create"
                  @click="onAddBot"
                >
                  <fai :icon="['fas', 'check']" class="mr-2" />
                  Add bot
                </v-btn>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </section>

    <!-- proxies -->
    <section>
      <h4 class="text-uppercase text-body-2 font-weight-semibold primary500--text mb-2">
        Proxies in use
      </h4>
      <v-row v-if="proxies.length" dense>
        <v-col v-for="(proxy, i) in proxies" :key="i" cols="12" md="6">
          <div class="grey600 py-1 px-2 text-caption">
            {{ proxy }}
          </div>
        </v-col>
      </v-row>

      <p v-else class="text--disabled">
        No proxies in use
      </p>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { debounce } from '@/utils'

import FileDropArea from '@/components/FileDropArea'
import AdminBotPropEdit from './components/AdminBotPropEdit'

function readFile(file) {
  return new Promise((resolve, reject) => {
    const fr = new FileReader()
    fr.onload = () => {
      resolve(fr.result)
    }
    fr.onerror = reject
    fr.readAsText(file)
  })
}

const CREATE_OBJ_MAP = {
  shared_secret: 'sharedSecret',
  identity_secret: 'identitySecret',
  account_name: 'username',
}

const newBotFormDefault = () => ({
  steamid: null,
  type: null,
  tradeUrl: null,
  username: null,
  password: null,
  sharedSecret: null,
  identitySecret: null,
  csdealsKey: null,
  csdealsTotp: null,
  proxy: null,
  disabled: false,
})

export default {
  components: {
    AdminBotPropEdit,
    FileDropArea,
    CopyInput: () => import('@/components/CopyInput'),
  },
  data() {
    return {
      loading: {
        list: false,
        proxies: false,
        create: false,
        quick: false,
      },
      total: 0,
      expanded: [],
      search: '',
      options: {
        sortBy: ['createdAt'],
        sortDesc: [true],
        itemsPerPage: 10,
        mustSort: true,
      },
      headers: [
        { text: 'Bot', value: 'username', align: 'start' },
        {
          text: 'Items', value: 'stats.itemCount', sortable: false, align: 'start',
        },
        {
          text: 'Trades', value: 'stats.tradeCount', sortable: false, align: 'start',
        },
        {
          text: 'cs.deals', value: 'stats.csdealsBalance', sortable: false, align: 'start',
        },
        {
          text: 'Created', value: 'createdAt', align: 'end',
        },
        {
          text: null, value: 'actions', sortable: false, align: 'end',
        },
      ],
      newBotForm: newBotFormDefault(),
      quickOpts: null,
      files: [],
      proxies: [],
      entries: [],
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/session',
    }),
  },
  watch: {
    options: {
      handler() {
        this.fetchApiData()
      },
      deep: true,
    },
    search: {
      handler(val) {
        this.onSearchInput(val)
      },
      deep: true,
    },
    files(files) {
      if (files.length) {
        this.onFilesDropped(files)
        this.files = []
      }
    },
  },
  created() {
    this.onGetProxies()
  },
  methods: {
    botIcon(type) {
      if (type === 'storage') return ['fad', 'box-full']
      if (type === 'transfer') return ['fad', 'arrow-alt-square-up']
      if (type === 'sell') return ['fad', 'coins']
      if (type === 'buy') return ['fad', 'sack-dollar']

      return ['fas', 'question-circle']
    },
    onExpandItem(item) {
      const [expandedItem] = this.expanded

      if (item.username === expandedItem?.username) {
        this.expanded = []
      } else {
        this.expanded = [item]
        this.quickOpts = null
      }
    },
    onPagination() {
      this.expanded = []
      this.quickOpts = null
    },
    onSearchInput: debounce(function fn(val) {
      this.fetchApiData()
    }, 500),
    async fetchApiData() {
      this.loading.list = true

      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options

      try {
        const { entries, total } = await this.$socket.request('admin.bots.get', {
          page,
          sortBy: sortBy[0],
          asc: !sortDesc[0],
          limit: itemsPerPage,
          search: this.search,
        })

        this.entries = entries
        this.total = total
      } catch (error) {
        this.entries = []
        this.$toast.error(error.message)
      } finally {
        this.loading.list = false
      }
    },
    async onAddBot() {
      this.loading.create = true

      try {
        const bot = await this.$socket.request('admin.bots.create', { ...this.newBotForm })

        this.newBotForm = newBotFormDefault()
        this.$toast.success(`Bot "${bot.username}" added successfully.`)
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading.create = false
      }
    },
    async onGetProxies() {
      this.loading.proxies = true

      try {
        const proxies = await this.$socket.request('admin.bots.proxies')
        this.proxies = proxies.map(i => i.proxy)
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading.proxies = false
      }
    },
    async onPropEdit(form) {
      this.loading.create = true

      try {
        const val = await this.$socket.request('admin.bots.setProperty', form)

        this.expanded[0][form.field] = val
        this.expanded = []

        this.$toast.success('Property updated successfully.')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading.create = false
      }
    },
    async onGetBtcAddr(bot) {
      this.loading.quick = true

      try {
        const val = await this.$socket.request('admin.bots.getCsdealsBtcDepoAddr', bot.username)
        this.quickOpts = val
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading.quick = false
      }
    },
    async onGetTradeUrl(bot) {
      this.loading.quick = true

      try {
        const val = await this.$socket.request('admin.bots.getTradeUrl', bot.username)
        this.quickOpts = val
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading.quick = false
      }
    },
    async onFilesDropped(files) {
      console.log(files)

      for (const file of files) {
        // eslint-disable-next-line no-await-in-loop
        const content = await readFile(file)

        try {
          const json = JSON.parse(content)
          console.log(json)

          for (const [k, v] of Object.entries(json)) {
            const key = CREATE_OBJ_MAP[k] ?? k
            if (Object.prototype.hasOwnProperty.call(this.newBotForm, key)) {
              this.newBotForm[key] = v
            }
          }

          const steamid = json.Session?.SteamLogin?.split('%7C%7C')?.[0]
          if (steamid) {
            this.newBotForm.steamid = steamid
          }
        } catch (error) {
          this.$toast.error(`${file.name} is not valid JSON`)
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.data-table ::v-deep {
  background-color: grey(600);

  .v-data-table-header {
    background-color: grey(500);
  }

  .v-data-footer {
    background-color: grey(500);
  }
}

.user-obj {
  overflow: auto;
}

.pagination ::v-deep {
  .v-pagination__item,
  .v-pagination__navigation {
    border-radius: 0;
    background-color: grey(500);
    box-shadow: none;
  }
}
</style>
